function SuperProgressBar({ bgcolor, textlabel, progress }) {
  const containerProgressStyle = {
    height: '20px',
    width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: '10px',
    position: 'relative', // Necesario para el posicionamiento absoluto del texto
  };

  const fillerProgressStyle = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: parseInt(progress) === 100 ? '10px' : '10px 0px 0px 10px',
  };

  const labelProgressStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Centra el texto en ambas direcciones
    color: '#3b3b3b',
    fontWeight: 'bold',
    whiteSpace: 'nowrap', // Evita que el texto se divida en varias líneas
  };

  return (
    <div style={containerProgressStyle}>
      <div style={fillerProgressStyle}></div>
      <span style={labelProgressStyle}>{textlabel}</span>
    </div>
  );
}

export default SuperProgressBar;